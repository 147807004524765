import * as React from 'react';
import {DateTime as luxon} from 'luxon';
import {cloneDeep, compact, reject, orderBy} from 'lodash-es';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  FormItem,
  Help,
  Icon,
  Paragraph,
  Popover,
  Select,
  Spinner,
  Stack,
  Table,
  Template,
  TIcons,
  Toggle,
  TagList,
  ITableCol,
} from '@pluto-tv/assemble';

import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';
import AvailabilityWindowForm, {IAvailbilityWindow} from 'components/availability-window-form';
import {useFindQuery as useFindChannelsQuery} from 'features/channels/channelsApi';
import {useAppPermissions} from 'app/permissions';
import {useUserRegions} from 'helpers/useUserRegions';
import {IPopover} from 'helpers/popoverInterface';
import {programmingTypes} from 'helpers/programmingTypes';

import {INestedClipProps} from '../nestedPropsInterface';

export default ({model, setFields, onBlur, form}: INestedClipProps): JSX.Element => {
  const {ableTo, permissions} = useAppPermissions();
  const CAN_EDIT = ableTo('CLIP_EDIT');

  const {territories, isFetching: isUserRegionsFetching, isError: isUserRegionsError} = useUserRegions();

  const {
    data: channels,
    isError: isErrorChannels,
    isFetching: isFetchingChannels,
  } = useFindChannelsQuery(
    model.activeRegion
      ? {
          kidsMode: true,
          activeRegion: model.activeRegion,
        }
      : skipToken,
  );

  const [availLinearWindowOpen, setLinearAvailWindowOpen] = React.useState<IPopover>({});
  const [availAvodWindowOpen, setAvodAvailWindowOpen] = React.useState<IPopover>({});

  const updateAvailWindow = (availWindow: IAvailbilityWindow, index = -1, windowType: 'linear' | 'AVOD') => {
    const cloned: IAvailbilityWindow[] = cloneDeep(
      model.availabilityWindows && model.availabilityWindows[windowType]
        ? (model.availabilityWindows[windowType] as IAvailbilityWindow[])
        : [],
    );

    if (index >= 0) {
      cloned.splice(index, 1, availWindow);
    } else {
      cloned.push(availWindow);
    }

    setFields({
      availabilityWindows: {
        ...model.availabilityWindows,
        [windowType]: cloned,
      },
    });

    setLinearAvailWindowOpen({});
    setAvodAvailWindowOpen({});
  };

  const handleAvailWindowClick = (icon: TIcons, index: number, windowType: 'linear' | 'AVOD') => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.availabilityWindows);

      if (!cloned) {
        return;
      }

      cloned[windowType]?.splice(index, 1);

      setFields({
        availabilityWindows: cloned,
      });
    } else if (icon === 'edit') {
      if (windowType === 'linear') {
        setAvodAvailWindowOpen({});
        setLinearAvailWindowOpen({[index]: true});
      } else {
        setLinearAvailWindowOpen({});
        setAvodAvailWindowOpen({[index]: true});
      }
    }
  };

  if (isUserRegionsError) {
    return <CrudError error='Error loading page data' />;
  }

  if (isUserRegionsFetching || isFetchingChannels) {
    return (
      <Box fullHeight={true}>
        <Spinner id='settingsSpinner' center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='Territories'>
          <Stack space='small'>
            <FormItem
              {...form.regionFilter}
              permission={permissions.CLIP_EDIT}
              onBlur={() => onBlur('regionFilter', false)}
            >
              <Select
                placeholder='Please select territories'
                onChange={value =>
                  setFields({
                    regionFilter: (value || [])?.map(v => v.value),
                  })
                }
                value={model.regionFilter?.map(d => ({label: d, value: d}))}
                id='territories'
                clearable={true}
                searchable={true}
                addAll={true}
                multiselect={true}
                predicate='value'
                options={
                  orderBy(
                    (territories || []).map(t => ({label: t.name, value: t.id.toLowerCase()})),
                    'label',
                  ) || []
                }
              />
            </FormItem>
          </Stack>
        </ContentBox>
        <ContentBox title='Availability Windows'>
          <Stack space='medium'>
            <Box marginTop='xsmallNegative'>
              <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                <Help state='info'>
                  All dates are on PDT. Start date will be set at 00:00:00, and end date at 23:59:59.
                </Help>
              </Icon>
            </Box>
            {form.availabilityWindows?.helpText && <Help state='error'>{form.availabilityWindows?.helpText}</Help>}
            <Stack space='small'>
              <Cluster align='center' space='medium'>
                <Paragraph>Linear</Paragraph>
                <Toggle
                  id='lineartoggle'
                  label='Yes'
                  value={model.distributeAs?.linear}
                  onChange={val => setFields({distributeAs: {...model.distributeAs!, linear: val}})}
                  permission={permissions.CLIP_EDIT}
                />
              </Cluster>
              <Table
                id='linearAvailWindowTable'
                cols={[
                  {
                    label: 'Start Date',
                    transform: row =>
                      luxon
                        .fromISO(row.startDate as string)
                        .setZone('America/Los_Angeles')
                        .toFormat('yyyy-LL-dd, HH:mm:ss'),
                  },
                  {
                    label: 'End Date',
                    transform: row =>
                      luxon
                        .fromISO(row.endDate as string)
                        .setZone('America/Los_Angeles')
                        .toFormat('yyyy-LL-dd, HH:mm:ss'),
                  },
                  {
                    label: 'Screenings',
                    field: 'screenings',
                  },
                  ...(CAN_EDIT
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: (row, _col, index) => (
                            <TableActions
                              row={row}
                              deleteOption={true}
                              displayField='name'
                              altTitle='availability window'
                              icons={[]}
                              onClick={(row, icon) => handleAvailWindowClick(icon, index, 'linear')}
                            >
                              <Popover
                                id='editLinearAvailableWindowPopover'
                                appendToBody={true}
                                manualTrigger={true}
                                visible={availLinearWindowOpen[index]}
                                onClickOutside={() => setLinearAvailWindowOpen({})}
                              >
                                <Template label='trigger'>
                                  <Icon
                                    space='small'
                                    icon='edit'
                                    onClick={() => handleAvailWindowClick('edit', index, 'linear')}
                                  />
                                </Template>
                                <Template label='popover'>
                                  <AvailabilityWindowForm
                                    onCancel={() => setLinearAvailWindowOpen({})}
                                    value={row}
                                    onSave={val => updateAvailWindow(val, index, 'linear')}
                                  />
                                </Template>
                              </Popover>
                            </TableActions>
                          ),
                        } as ITableCol<IAvailbilityWindow>,
                      ]
                    : []),
                ]}
                rows={model.availabilityWindows?.linear || []}
                emptyMsg='No Linear Availability Windows'
              ></Table>
              <Cluster justify='space-between'>
                <div></div>
                {CAN_EDIT && (
                  <Popover
                    id='linearAvailableWindowPopover'
                    manualTrigger={true}
                    visible={availLinearWindowOpen['add']}
                    onClickOutside={() => setLinearAvailWindowOpen({})}
                  >
                    <Template label='trigger'>
                      <Button
                        id='addLinearAvailableWindow'
                        type='primary'
                        onClick={() => setLinearAvailWindowOpen({add: true})}
                      >
                        + Add
                      </Button>
                    </Template>
                    <Template label='popover'>
                      <AvailabilityWindowForm
                        onCancel={() => setLinearAvailWindowOpen({})}
                        onSave={val => updateAvailWindow(val, -1, 'linear')}
                      />
                    </Template>
                  </Popover>
                )}
              </Cluster>
              <Cluster align='center' space='medium'>
                <Paragraph>AVOD</Paragraph>
                <Toggle
                  id='avodtoggle'
                  label='Yes'
                  value={model.distributeAs?.AVOD}
                  onChange={val => setFields({distributeAs: {...model.distributeAs!, AVOD: val}})}
                  permission={permissions.CLIP_EDIT}
                />
              </Cluster>
              <Table
                id='avodAvailWindowTable'
                cols={[
                  {
                    label: 'Start Date',
                    transform: row =>
                      luxon
                        .fromISO(row.startDate as string)
                        .setZone('America/Los_Angeles')
                        .toFormat('yyyy-LL-dd, HH:mm:ss'),
                  },
                  {
                    label: 'End Date',
                    transform: row =>
                      luxon
                        .fromISO(row.endDate as string)
                        .setZone('America/Los_Angeles')
                        .toFormat('yyyy-LL-dd, HH:mm:ss'),
                  },
                  {
                    label: 'Screenings',
                    field: 'screenings',
                  },
                  ...(CAN_EDIT
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: (row, _col, index) => (
                            <TableActions
                              row={row}
                              deleteOption={true}
                              displayField='name'
                              altTitle='availability window'
                              icons={[]}
                              onClick={(row, icon) => handleAvailWindowClick(icon, index, 'AVOD')}
                            >
                              <Popover
                                id='editAvodAvailableWindowPopover'
                                appendToBody={true}
                                manualTrigger={true}
                                visible={availAvodWindowOpen[index]}
                                onClickOutside={() => setAvodAvailWindowOpen({})}
                              >
                                <Template label='trigger'>
                                  <Icon
                                    space='small'
                                    icon='edit'
                                    onClick={() => handleAvailWindowClick('edit', index, 'AVOD')}
                                  />
                                </Template>
                                <Template label='popover'>
                                  <AvailabilityWindowForm
                                    onCancel={() => setAvodAvailWindowOpen({})}
                                    value={row}
                                    onSave={val => updateAvailWindow(val, index, 'AVOD')}
                                  />
                                </Template>
                              </Popover>
                            </TableActions>
                          ),
                        } as ITableCol<IAvailbilityWindow>,
                      ]
                    : []),
                ]}
                emptyMsg='No AVOD Availability Windows'
                rows={model.availabilityWindows?.AVOD || []}
              ></Table>
              <Cluster justify='space-between'>
                <div></div>
                {CAN_EDIT && (
                  <Popover
                    id='avodAvailableWindowPopover'
                    manualTrigger={true}
                    visible={availAvodWindowOpen.add}
                    onClickOutside={() => setAvodAvailWindowOpen({})}
                  >
                    <Template label='trigger'>
                      <Button
                        id='addAvodAvailableWindow'
                        type='primary'
                        permission={permissions.CLIP_EDIT}
                        onClick={() => setAvodAvailWindowOpen({add: true})}
                      >
                        + Add
                      </Button>
                    </Template>
                    <Template label='popover'>
                      <AvailabilityWindowForm
                        onCancel={() => setAvodAvailWindowOpen({})}
                        onSave={val => updateAvailWindow(val, -1, 'AVOD')}
                      />
                    </Template>
                  </Popover>
                )}
              </Cluster>
            </Stack>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Settings'>
          <Stack space='small'>
            <Cluster space='xxxlarge'>
              <FormItem child='Toggle' {...form.promotional} permission={permissions.CLIP_EDIT}>
                <Toggle
                  id='promotional'
                  label='Yes'
                  value={model.promotional}
                  onChange={val => setFields({promotional: val})}
                />
              </FormItem>
              <FormItem child='Toggle' {...form.liveBroadcast} permission={permissions.CLIP_EDIT}>
                <Toggle
                  id='liveBroadcast'
                  label='Yes'
                  value={model.liveBroadcast}
                  onChange={val => setFields({liveBroadcast: val})}
                />
              </FormItem>
              <FormItem child='Toggle' {...form.captionsRequired} permission={permissions.CLIP_EDIT}>
                <Toggle
                  id='captionsRequired'
                  label='Yes'
                  value={model.captionsRequired}
                  onChange={val => setFields({captionsRequired: val})}
                />
              </FormItem>
            </Cluster>
            <Stack space='medium'>
              <FormItem {...form.programmingType} permission={permissions.CLIP_EDIT}>
                <Select
                  placeholder='Select a Programming Type'
                  clearable={true}
                  onChange={value => setFields({programmingType: value?.label})}
                  value={{label: model.programmingType || ''}}
                  id='programming-type'
                  options={programmingTypes.map(pT => ({label: pT}))}
                />
              </FormItem>
              <FormItem {...form.channelAssociation} permission={permissions.CLIP_EDIT}>
                <Select
                  clearable={true}
                  placeholder={
                    isErrorChannels ? 'There are no kids channels available for this region' : 'Select a channel'
                  }
                  onChange={value => {
                    const channelId: string = value?.value;

                    const tags = orderBy(
                      compact([
                        ...new Set([
                          ...reject(model.tags || [], tag => tag.indexOf('channel_id_') > -1),
                          channelId && `channel_id_${channelId}`,
                        ] as string[]),
                      ]),
                      tag => tag.toLowerCase(),
                    );

                    setFields({
                      tags,
                      channelAssociation: channelId,
                    });
                  }}
                  value={{label: model.channelAssociation || '', value: model.channelAssociation || ''}}
                  id='rating'
                  predicate='value'
                  options={orderBy(
                    (channels?.data || []).map(channel => ({label: channel.name, value: channel.id})),
                    channel => channel.label.toLowerCase(),
                  )}
                />
              </FormItem>
            </Stack>
          </Stack>
        </ContentBox>
        <ContentBox title='Tags'>
          <Stack space='medium'>
            <FormItem {...form.tags} permission={permissions.CLIP_EDIT}>
              <TagList
                value={model.tags}
                onChange={value =>
                  setFields({tags: orderBy([...new Set(value || [])] as string[], tag => tag.toLowerCase())})
                }
                id='tags'
              />
            </FormItem>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
