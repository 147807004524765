import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Divider,
  Heading,
  Icon,
  Popover,
  Spinner,
  Stack,
  Status,
  Template,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';
import {cloneDeep, intersection, some, uniq, uniqBy} from 'lodash-es';

import {useFindByIdQuery, useUpdateMutation} from 'features/vodCollections/vodCollectionsApi';
import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';
import {useBulkInsertEntriesMutation} from 'features/vodCategoryEntries/vodCategoryEntriesApi';

import {
  vodCollectionDetailsValidator,
  vodCollectionEditValidator,
  vodCollectionDistributionValidator,
  vodCollectionProgramValidator,
  vodCollectionArtworksValidator,
} from '../validators';

import {useAppPermissions} from 'app/permissions';
import ConfirmRouteChange from 'components/confirmRouteChange';
import programmingRoutes from 'routes/programming.routes';
import {IVodCollection} from 'models/vodCollections';
import {IVodCategoryTitle} from 'models/vodCategoryEntry';

import VodCollectionDetails from './details';
import VodCollectionDistribution from './distribution';
import VodCollectionArtwork from './artwork';
import VodCollectionProgram from './program';
import CrudError from 'components/crudError';
import {useFindQuery as useFindTerritoriesQuery} from 'features/territories/territoriesApi';

const vodCollectionTabs = ['details', 'distribution', 'artwork', 'program'] as const;

export type TVodCollectionTabs = typeof vodCollectionTabs[number];

const getActiveTab = (pathname: string): TVodCollectionTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as TVodCollectionTabs;

  if (vodCollectionTabs.includes(nestedPath)) {
    return nestedPath;
  }

  return 'details';
};

export default (): JSX.Element => {
  const {id}: {id: string} = useParams();
  const {permissions} = useAppPermissions();
  const history = useHistory();
  const activeTab: TVodCollectionTabs = getActiveTab(location.pathname);

  const [publishOpen, setPublishOpen] = React.useState(false);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [hasFlaggedFields, setHasFlaggedFields] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);

  const [newTitles, setNewTitles] = React.useState<IVodCategoryTitle[]>([]);

  const {
    data: vodCollection,
    isError: isVodCollectionError,
    error: vodCollectionError,
    isLoading: isVodCollectionFetching,
  } = useFindByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const {data: territories} = useFindTerritoriesQuery();
  const {data: mainCategories} = useFindMainCategoriesQuery();

  const [updateVodCollection] = useUpdateMutation();
  const [bulkInsertEntries] = useBulkInsertEntriesMutation();

  const [activeVodCollection, setActiveVodCollection] = React.useState<IVodCollection>();
  const [territoriesList, setTerritoriesList] = React.useState<string[]>([]);

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<IVodCollection>([
    ...vodCollectionEditValidator,
    ...vodCollectionDetailsValidator,
    ...vodCollectionDistributionValidator,
    ...vodCollectionProgramValidator,
    ...vodCollectionArtworksValidator,
  ]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(programmingRoutes.paths.vodCollectionListPage);
    }
  }, [isLeaving, history]);

  React.useEffect(() => {
    if (vodCollection) {
      setActiveVodCollection(vodCollection);
    }
  }, [vodCollection]);

  React.useEffect(() => {
    if (territories?.length) {
      setTerritoriesList(
        [...territories].sort((r1, r2) => (r1.name.toUpperCase() > r2.name.toUpperCase() ? 1 : -1)).map(r => r.id),
      );
    }
  }, [territories]);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  const saveData = React.useCallback(async () => {
    setIsSaving(true);
    // Save data to API here
    try {
      if (newTitles && newTitles.length > 0) {
        const vodCategoryId = String(model.id);
        const seriesIds: string[] = [];
        const episodeIds: string[] = [];
        newTitles.forEach(e => {
          if (e.seriesType.toLocaleLowerCase() === 'tv') {
            seriesIds.push(e.contentId);
          }
          if (e.seriesType.toLocaleLowerCase() === 'film') {
            episodeIds.push(e.contentId);
          }
        });
        const response = await bulkInsertEntries({
          vodCategoryId,
          episodeIds: uniq(episodeIds),
          seriesIds: uniq(seriesIds),
          resolveEpisodeIds: true,
        });
        if ('error' in response) {
          if ((response.error as any)?.data.error) {
            Toast.error('Error', `${(response.error as any)?.data.message}.`);
          } else {
            Toast.error('Error', `${response.error}.`);
          }
        } else {
          setNewTitles([]);
          Toast.success('Success', 'VOD titles were added successfully');
        }
      } else {
        const result = await updateVodCollection({
          id,
          vodCollection: {
            ...model,
            categories: model.categories?.map(c => ({...c, order: c.order || 0})),
          },
          fields: Object.keys(dirtyFields),
        }).unwrap();
        Toast.success('Success', 'VOD Collection Updated');
        setActiveVodCollection(result);
      }
    } catch (e) {
      const errorMessage = (e as any).data?.message;
      if (errorMessage && errorMessage.match('Hero Carousel')) {
        const [heroCarouselName] = errorMessage.match(/\'[^\)]+\'/g);
        Toast.error(
          'Error',
          `${heroCarouselName.slice(
            1,
            -1,
          )} is currently configured as Hero Carousel for ${model.activeRegion?.toUpperCase()}`,
        );
      } else {
        Toast.error('Error', errorMessage || 'Failed to save VOD Collection. Please try again.');
      }
    } finally {
      setIsSaving(false);
    }
  }, [id, model, dirtyFields, updateVodCollection, newTitles, bulkInsertEntries]);

  React.useEffect(() => {
    // Fields we need to have special messaging for
    const checkedFields = ['duration', 'breakpoints', 'origin'];

    setHasFlaggedFields(some(checkedFields, field => dirtyFields[field]));
  }, [dirtyFields]);

  const togglePublishOpen = React.useCallback(() => {
    setPublishOpen(prev => !prev);
  }, []);

  const toggleArchiveOpen = React.useCallback(() => {
    setArchiveOpen(prev => !prev);
  }, []);

  // Handler for channel initial load or after save
  React.useEffect(() => {
    if (activeVodCollection) {
      const clonedVod = cloneDeep(activeVodCollection);

      // For reference
      // https://plutotv.atlassian.net/browse/CMS-3196
      // https://plutotv.atlassian.net/browse/CMS-3218
      const distributionInclude = clonedVod.distribution?.include?.sort() || [];
      const distributionExclude = clonedVod.distribution?.exclude?.sort() || [];
      const regionFilterInclude = (intersection(territoriesList, clonedVod.regionFilter?.include) || []).sort();
      const regionFilterExclude = (intersection(territoriesList, clonedVod.regionFilter?.exclude) || []).sort();

      clonedVod.distribution = {
        include: distributionInclude,
        exclude: distributionExclude,
      };

      clonedVod.regionFilter = {
        include: regionFilterInclude,
        exclude: regionFilterExclude,
      };

      if (mainCategories && clonedVod.categories) {
        clonedVod.categories = clonedVod.categories
          .map(ct => ({...ct, name: mainCategories.find(mc => mc.id === ct.catId)?.name}))
          .sort((c1, c2) => (c1.name! > c2.name! ? 1 : -1));
      }
      setModel(clonedVod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVodCollection, mainCategories]);

  if (isVodCollectionFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (isVodCollectionError) {
    return <CrudError error={vodCollectionError} />;
  }

  return (
    <>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty || newTitles.length > 0 : false}
        onSave={saveData}
        hasFlaggedFields={hasFlaggedFields}
        isValid={formState.isValid || newTitles.length > 0}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='xxsmall'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  {pristineModel.name}
                </Heading>
                <Cluster align='flex-start' space='medium' wrap={false}>
                  <Cluster space='xxsmall' wrap={false}>
                    <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                      Active Region:
                    </Heading>
                    <Heading level='h5' color='primary'>
                      {model.activeRegion?.toUpperCase()}
                    </Heading>
                  </Cluster>
                  <Heading level='h5' color='secondary'>
                    |
                  </Heading>
                  <Cluster space='xxsmall' wrap={false} fullWidth={true}>
                    <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                      Main Categories:
                    </Heading>
                    <Heading level='h5' color='primary' truncate={true} truncateBackgroundHover='shadow'>
                      {model.categories
                        ?.filter(c => c.name || c.categoryDetails?.name)
                        ?.map(c => c.name || c.categoryDetails?.name)
                        ?.join(' , ')}
                    </Heading>
                  </Cluster>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                <Popover manualTrigger={true} visible={archiveOpen} onClickOutside={() => setArchiveOpen(false)}>
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      state={model.enabled ? 'disabled' : 'enabled'}
                      onClick={() => toggleArchiveOpen()}
                      permission={permissions.VOD_EDIT}
                    >
                      {model.archived ? (
                        <Icon space='xxxsmall' icon='archive' color='neutral'>
                          Archived&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Icon>
                      ) : (
                        <Icon space='xxxsmall' icon='check' color='success'>
                          Not Archived
                        </Icon>
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='xxxxsmall'>
                      <Icon
                        space='xxxsmall'
                        icon='check'
                        color='success'
                        onClick={() => {
                          setFields({archived: false});
                          toggleArchiveOpen();
                        }}
                      >
                        Not Archived
                      </Icon>
                      <Icon
                        space='xxxsmall'
                        icon='archive'
                        color='neutral'
                        onClick={() => {
                          setFields({archived: true});
                          toggleArchiveOpen();
                        }}
                      >
                        Archived
                      </Icon>
                    </Stack>
                  </Template>
                </Popover>
                <Popover manualTrigger={true} visible={publishOpen} onClickOutside={() => setPublishOpen(false)}>
                  <Template label='trigger'>
                    <Button
                      type='solid'
                      icon='arrowdown'
                      iconPosition='right'
                      onClick={() => togglePublishOpen()}
                      permission={permissions.VOD_EDIT}
                    >
                      {model.enabled ? (
                        <Status label='Published&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' state='success' />
                      ) : (
                        <Status label='Unpublished' state='neutral' />
                      )}
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Stack space='xxxxsmall'>
                      <Status
                        label='Unpublished'
                        state='neutral'
                        onClick={() => {
                          setFields({enabled: false});
                          togglePublishOpen();
                        }}
                      />
                      <Status
                        label='Published'
                        state='success'
                        onClick={() => {
                          setFields({enabled: true, archived: false});
                          togglePublishOpen();
                        }}
                      />
                    </Stack>
                  </Template>
                </Popover>
              </Cluster>
            </Cluster>
            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster buttonGroup={true} justify='center' wrap={false}>
              <Button
                active={activeTab === 'details'}
                onClick={() => history.push(programmingRoutes.paths.vodCollectionEditDetailsPage.replace(':id', id))}
              >
                Details
              </Button>
              <Button
                active={activeTab === 'distribution'}
                onClick={() =>
                  history.push(programmingRoutes.paths.vodCollectionEditDistributionPage.replace(':id', id))
                }
              >
                Distribution
              </Button>
              <Button
                active={activeTab === 'artwork'}
                onClick={() => history.push(programmingRoutes.paths.vodCollectionEditArtworkPage.replace(':id', id))}
              >
                Artwork
              </Button>

              <Button
                active={activeTab === 'program'}
                onClick={() => history.push(programmingRoutes.paths.vodCollectionEditProgramPage.replace(':id', id))}
              >
                Program
              </Button>
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>
          {activeTab === 'details' && (
            <VodCollectionDetails
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'distribution' && (
            <VodCollectionDistribution
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'artwork' && (
            <VodCollectionArtwork
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              dirtyFields={dirtyFields}
            />
          )}
          {activeTab === 'program' && (
            <VodCollectionProgram
              form={form}
              model={model}
              onBlur={onBlur}
              onChange={onChange}
              pristineModel={pristineModel}
              setFields={setFields}
              onAddTitles={(addedTitles: IVodCategoryTitle[], isRemoving = false) => {
                return isRemoving
                  ? setNewTitles(uniqBy(addedTitles, 'contentId'))
                  : setNewTitles(prev => uniqBy([...prev, ...addedTitles], 'contentId'));
              }}
              dirtyFields={dirtyFields}
            />
          )}
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={cancelHandler} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={
                    newTitles.length > 0
                      ? ''
                      : !formState.isValid || !formState.isDirty
                      ? 'disabled'
                      : isSaving
                      ? 'thinking'
                      : ''
                  }
                  onClick={saveData}
                  id='save'
                  permission={permissions.VOD_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
